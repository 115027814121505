//---------selectpicker-----------//
app.directive('selectpicker', function($timeout)
{
  return {
      restrict: 'E',
      scope: {
          array: '=',
          model: '=',
          class: '=',
          selected: '=',
          list: '&'

      },
      transclude: true,
      template: '<select ng-options="o.value as o.option for o in array"><option value=""></option></select>',
      replace: true,
      link: function(scope, element, attrs) {
         $(element).selectpicker();
         $(element).selectpicker('refresh');
      }
  }
});
//---------multiple selectpicker-----------//
app.directive('multiselectpicker', function() {
    return {
        restrict: 'E',
        scope: {
            array: '=',
            model: '=',
            class: '='
        },
        template: '<select ng-model="model" multiple data-selected-text-format="count" ng-options="o.value as o.option for o in array"></select>',
        replace: true,
        link: function(scope, element, attrs) {
            $(element).selectpicker();
        }
    }
});

//---------option group selectpicker-----------//
app.directive('optgroupselectpicker', function($timeout)
{
 return {
 restrict: 'E',
 scope: {
 array: '=',
 model: '=',
 class: '=',
 selected: '=',
 list: '&'

 },
 transclude: true,
 template: '<select ng-options="o.value as o.option group by o.group for o in array"><option value=""></option></select>',
 replace: true,
 link: function(scope, element, attrs) {
 $(element).selectpicker();
 $(element).selectpicker('refresh');
 }
 }
});


//---------ng-file directive for file upload-----------//
app.directive('ngFiles', ['$parse', function ($parse) {
    function fn_link(scope, element, attrs) {
        var onChange = $parse(attrs.ngFiles);
        element.on('change', function (event) {
            onChange(scope, { $files: event.target.files });
        });
    };

    return {
        link: fn_link
    }
} ]);


//Directive for tabs popups---/
  app.directive('tabs', function() {

    return {
      restrict: 'E',
      transclude: true,
      scope: {},
      controller: ["$scope", function($scope) {
        var panes = $scope.panes = [];

        $scope.select = function(pane) {
          angular.forEach(panes, function(pane) {
            pane.selected = false;
          });
          pane.selected = true;
        }

        this.addPane = function(pane) {
          if (panes.length == 0) $scope.select(pane);
          panes.push(pane);
        }

      }],
      template:
        '<div class="tabs">' +
          '<ul class="nav nav-tabs">' +
            '<li ng-repeat="pane in panes" ng-class="{active:pane.selected, disabled:form.vacancy_order_form.$invalid}">'+
              '<a translate="" ng-click="select(pane)" id="pane{{ $index }}">{{pane.heading}}</a>' +
              '<span class="tab-arrow"><i class="fa fa-caret-up" aria-hidden="true"></i></span>' +
            '</li>' +
          '</ul>' +
          '<div class="tab-content" ng-transclude></div>' +
        '</div>',
      replace: true
    };
  }).
  directive('pane', function() {
    return {
      require: '^tabs',
      restrict: 'E',
      transclude: true,
      scope: { heading: '@' },
      link: function(scope, element, attrs, tabsCtrl) {
        tabsCtrl.addPane(scope);
      },
      template:
        '<div class="tab-pane" ng-class="{active: selected}" ng-transclude>' +
        '</div>',
      replace: true
    };
})

app.directive('backButton', function(){
    return {
      restrict: 'A',

      link: function(scope, element, attrs) {
        element.bind('click', goBack);

        function goBack() {
          history.back();
          scope.$apply();
        }
      }
    }
});

app.directive("vacancyenddatepicker", function () {

  return {
      link: function(scope, element, attrs) {
        // CALL THE "datepicker()" METHOD USING THE "element" OBJECT.
        console.log(attrs)
        element.bind("#end").datepicker({
        dateFormat: "dd-mm-yy",
        changeMonth: true,
        changeYear: true,
      //  maxDate: new Date(attrs.maxDate)
         minDate: new Date(),
         //maxDate: new Date(),
      })
    }
  };

  }); 
app.directive("vacancydatepicker", function () {

  return {
      link: function(scope, element, attrs) {
        // CALL THE "datepicker()" METHOD USING THE "element" OBJECT.
        console.log(attrs)
        element.bind("#start").datepicker({
        dateFormat: "dd-mm-yy",
        changeMonth: true,
        changeYear: true,
      //  maxDate: new Date(attrs.maxDate)
         minDate: new Date(),
         //maxDate: new Date(),
      })
    }
  };

  }); 

app.directive("leftdatepicker", function () {

  return {
      link: function(scope, element, attrs) {
        var end_date = attrs.endDate;
        var min_date = attrs.minDate;
        min_date = min_date.split("/");				
        min_date = min_date[2]+"-"+min_date[1]+"-"+min_date[0];
        var max_date = min_date;

        min_date = new Date(min_date);
        max_date = new Date(max_date);
        end_date = new Date(end_date);
        
        //max_date.setMonth(max_date.getMonth()+3)
        
        max_date = new Date(max_date.getFullYear(), max_date.getMonth() + 1, 0);
        
        if(end_date<max_date)
        {
          max_date = end_date;
        }
        element.bind(".leftdate").datepicker({
          dateFormat: "dd-mm-yy",
          changeMonth:true,
          changeYear:true,
          minDate:min_date,
          maxDate: max_date
        })
    }
  };

});
